import service from "@/api/service";
import { GetResponse } from "@/api/corporate-shop/response";
import { SearchRequest } from "@/api/corporate-shop/request";
import { SearchResponse } from "@/api/corporate-shop/response";
import {CorporateRequest} from "@/api/corporate-shop/request";
import {CorporateResponse} from "@/api/corporate-shop/response";
import {RegisterRequest} from "@/api/corporate-shop/request";
import {RegisterResponse} from "@/api/corporate-shop/response";

/**
 * 会社概要管理店舗一覧・検索APIをコールします。
 *
 * @param searchRequest 会社概要管理店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-corporate", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 会社概要管理店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-corporate-shop");
  return response.data as GetResponse;
}

/**
 * 会社情報取得をコールします。
 *
 * @param corporateRequest 会社情報登録更新のリクエストボディ
 */
 export async function corporate(corporateRequest: CorporateRequest) {
     const response = await service.post("/get-corporate", corporateRequest);
     return response.data as CorporateResponse;
 }

/**
 * 会社情報取得登録更新APIをコールします。
 *
 * @param registerRequest
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
    const response = await service.post("/regist-corporate", registerRequest);
    return response.data as RegisterResponse;
}