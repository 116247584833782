import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/corporate-shop/request";
import { SearchItem } from "@/api/corporate-shop/response";
import CorporateShopSearch from "@/store/shop-corporate/search";
import CorporateShopGet from "@/store/shop-corporate/get";
import Flash, { ErrorAlert } from "@/store/common/flash";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDatePicker
  }
})
export default class index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "会社概要管理";
  headingSub = "Corporate management";
  breadCrumbs = [
    { text: "Webアプリ", disabled: true },
    { text: "会社概要管理", disabled: true },
    { text: "会社概要情報一覧", disabled: true }
  ];

  // テーブルヘッダ
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    {
      text: "",
      value: "custom",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
    return CorporateShopSearch.getItems;
  }

  /**
   * 総件数
   */
  get totalCount() {
    return CorporateShopSearch.getTotalCount;
  }

  get writeFlg() {
    return CorporateShopSearch.getWriteFlg;
  }

  get shopItems() {
    return CorporateShopGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    //await CorporateShopSearch.restore(this.inputOptions as SearchRequest);
    const request = CorporateShopSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
    (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
    (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await CorporateShopSearch.clearResponse();
    await CorporateShopGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行の設定ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async settingClickCallback(item: SearchItem) {
    await this.$router.push({
      name: "company-profile-management-edit",
      params: {shopId: String(item.id)},
      query: { shopName: item.name }
    });
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (CorporateShopGet.isSuccess) {
      return true;
    }

    await CorporateShopGet.get();
    if (!CorporateShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: CorporateShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return CorporateShopGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await CorporateShopSearch.search(request);
    if (!CorporateShopSearch.isSuccess) {
      await Flash.setErrorNow({
        message: CorporateShopSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }
  /**
   * 新規作成
   */
  /*
  async goToNew() {
    await this.$router.push({
      name: "app-notification-new",
      query: { shopId: this.shopid, shopName: this.shopName }
    });
  }
  */


  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    return request;
  }
}