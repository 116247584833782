import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/corporate-shop/response";
import * as CorporateShopAPI from "@/api/corporate-shop";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "shop-corporate/get";

/**
 * 会社概要管理店舗情報取得API（/get-corporate-shop）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItems() {
    if (this.getResponse.results) {
      return this.getResponse.results.items || [];
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  // MutationActions
  @MutationAction
  async get() {
    const getResponse = await CorporateShopAPI.get();
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
